'use client'

import { BookOpen, ClipboardList, Edit, LayoutList, LogOut, Menu, Users } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { cn } from '@/lib/utils'
import { APP_NAME } from '@/utils/app-config'
import { signOut, useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'

type MenuItem = {
	icon: React.ComponentType<{ className?: string }>
	labelKey: string
	href: string
	roles?: string[]
}

const menuItems: MenuItem[] = [
	{
		icon: ClipboardList,
		labelKey: 'my_programs',
		href: '/dashboard/my-programs',
		roles: ['CLIENT'],
	},
	{
		icon: BookOpen,
		labelKey: 'protocols',
		href: '/dashboard/protocols',
		roles: ['COACH', 'ADMIN'],
	},
	{
		icon: Edit,
		labelKey: 'posts',
		href: '/dashboard/posts',
		roles: ['COACH', 'ADMIN'],
	},
	{
		icon: LayoutList,
		labelKey: 'programs',
		href: '/dashboard/programs',
		roles: ['COACH', 'ADMIN'],
	},
	{
		icon: Users,
		labelKey: 'users',
		href: '/dashboard/users',
		roles: ['ADMIN'],
	},
]

export const Sidebar = ({ className }: { className?: string }) => {
	const { t } = useTranslation('common')
	const pathname = usePathname()
	const [open, setOpen] = useState(false)
	const { data: session, status } = useSession()

	const filteredMenuItems = menuItems.filter((item) => {
		if (!item.roles) return true
		if (!session?.user?.role) return false
		return item.roles.includes(session.user.role)
	})

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setOpen(false)
	}, [pathname])

	const handleLogout = async () => {
		await signOut({ callbackUrl: '/' })
	}

	return (
		<>
			{/* Menu Mobile */}
			<Sheet open={open} onOpenChange={setOpen}>
				<SheetTrigger asChild>
					<Button variant='soft' size='icon' className='lg:hidden m-0'>
						<Menu className='h-6 w-6 text-foreground' />
						<span className='sr-only'>Toggle Menu</span>
					</Button>
				</SheetTrigger>
				<SheetContent
					side='left'
					className='w-[240px] sm:w-[300px]  bg-gradient-light dark:bg-gradient-secondary text-white'
				>
					<SheetHeader>
						<SheetTitle className='sr-only'>Sidebar Menu</SheetTitle>
					</SheetHeader>
					<div className='space-y-4 py-4'>
						{/* Logo */}
						<div className='flex items-center mx-auto justify-center'>
							<Link href='/'>
								<Image
									alt='logo'
									src='/logo.svg'
									height={44}
									width={90}
									priority
									style={{ width: 84, height: 'auto' }}
								/>
								<span className='text-xl font-extrabold'>{APP_NAME}</span>
							</Link>
						</div>
						{/* Navigation */}
						<nav className='px-4 py-2'>
							<div className='space-y-1'>
								{filteredMenuItems.map((item) => {
									const isActive = pathname === item.href
									return (
										<Link
											key={item.href}
											href={item.href}
											className={cn(
												'flex items-center gap-3 rounded-lg px-3 py-2 transition-all',
												isActive
													? 'bg-card dark:bg-foreground text-secondary dark:text-secondary'
													: 'text-input dark:text-gray-200',
												isActive
													? 'hover:text-secondary dark:hover:text-secondary'
													: 'hover:font-semibold dark:hover:text-gray-50',
											)}
										>
											<item.icon className='h-4 w-4' />
											{t(item.labelKey)}
										</Link>
									)
								})}

								{status === 'authenticated' && (
									<Button
										variant='ghost'
										className='flex items-center text-md gap-3 rounded-lg px-3 py-2 transition-all text-red-500 hover:font-semibold'
										onClick={handleLogout}
									>
										<LogOut className='h-4 w-4' />
										{t('sign_out')}
									</Button>
								)}
							</div>
						</nav>
					</div>
				</SheetContent>
			</Sheet>

			{/* Sidebar Desktop */}
			<aside
				className={cn(
					'hidden lg:flex h-screen w-64 flex-col overflow-y-auto border-none bg-gradient-light dark:bg-gradient-secondary px-5 py-8 text-white',
					className,
				)}
			>
				<div className='space-y-4 py-4'>
					{/* Logo */}
					<div className='flex items-center mx-auto justify-center'>
						<Link href='/'>
							<Image alt='logo' src='/logo.svg' height={44} width={90} priority style={{ width: 84, height: 'auto' }} />
							<span className='text-xl font-extrabold'>{APP_NAME}</span>
						</Link>
					</div>

					{/* Navigation */}
					<nav className='px-4 py-2'>
						<div className='space-y-1'>
							{filteredMenuItems.map((item) => {
								const isActive = pathname === item.href
								return (
									<Link
										key={item.href}
										href={item.href}
										className={cn(
											'flex items-center gap-3 rounded-lg px-3 py-2 transition-all',
											isActive
												? 'bg-card dark:bg-foreground text-secondary dark:text-secondary'
												: 'text-gray-100 dark:text-gray-200',

											isActive
												? 'hover:text-secondary dark:hover:text-secondary'
												: 'hover:text-gray-900 dark:hover:text-gray-50 hover:bg-accent/80',
										)}
									>
										<item.icon className='h-4 w-4' />
										{t(item.labelKey)}
									</Link>
								)
							})}

							{status === 'authenticated' && (
								<Button
									variant='ghost'
									className='flex items-center text-md gap-3 rounded-lg px-3 py-2 transition-all text-red-500 hover:font-semibold'
									onClick={handleLogout}
								>
									<LogOut className='h-4 w-4' />
									{t('sign_out')}
								</Button>
							)}
						</div>
					</nav>
				</div>
			</aside>
		</>
	)
}
