// src/components/ui/card.tsx

import { cn } from '@/lib/utils'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

const cardVariants = cva('', {
	variants: {
		variant: {
			default: 'rounded-lg border bg-card text-card-foreground shadow-sm',
			primary:
				'sm:rounded-lg text-card-foreground shadow-sm bg-gradient-to-b from-card/40 via-lightGray to-card/90 dark:bg-gradient-card',
		},
	},
	defaultVariants: {
		variant: 'default',
	},
})

interface CardProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ className, variant, ...props }, ref) => (
	<div ref={ref} className={cn(cardVariants({ variant }), className)} {...props} />
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn('flex flex-col space-y-1.5 p-6', className)} {...props} />
	),
)
CardHeader.displayName = 'CardHeader'

const cardContentVariants = cva('', {
	variants: {
		variant: {
			default: 'p-6 pt-0',
			primary: 'pt-0',
		},
	},
	defaultVariants: {
		variant: 'default',
	},
})

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof cardContentVariants> {}

const CardContent = React.forwardRef<HTMLDivElement, CardContentProps>(({ className, variant, ...props }, ref) => (
	<div ref={ref} className={cn(cardContentVariants({ variant }), className)} {...props} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn('flex items-center p-6 pt-0', className)} {...props} />
	),
)
CardFooter.displayName = 'CardFooter'

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
	({ className, ...props }, ref) => (
		<h3 ref={ref} className={cn('font-lora text-2xl font-semibold leading-none tracking-wide', className)} {...props} />
	),
)
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn('text-sm text-card-foreground', className)} {...props} />
	),
)
CardDescription.displayName = 'CardDescription'

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
