//src/app/[locale]/home-client.tsx
'use client'

import PostCard from '@/components/post-card'
import SkeletonCard from '@/components/ui/skeleton-card'
import type { PostData as Post } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface BlogPostsProps {
	initialPosts: Post[]
}

export function BlogPosts({ initialPosts }: BlogPostsProps) {
	const { t } = useTranslation('posts')
	const [posts, setPosts] = useState<Post[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		// Fetch posts (simulate with a timeout if needed)
		const fetchPosts = async () => {
			try {
				// Simulate a delay
				await new Promise((resolve) => setTimeout(resolve, 2000))
				setPosts(initialPosts)
			} catch (error) {
				console.error('Erro ao buscar posts:', error)
				// Handle error if necessary
			} finally {
				setIsLoading(false)
			}
		}

		fetchPosts()
	}, [initialPosts])

	const skeletonKeys = ['skeleton-1', 'skeleton-2', 'skeleton-3', 'skeleton-4', 'skeleton-5']

	return (
		<main className='mx-4 sm:container min-h-screen flex-col items-center justify-between sm:p-24 bg-background'>
			{/* Posts Display */}
			<article>
				{isLoading ? (
					skeletonKeys.map((key) => <SkeletonCard key={key} />)
				) : posts.length === 0 ? (
					<p>{t('no_posts')}</p>
				) : (
					posts.map((post, index) => <PostCard post={post} key={post.id} isLcp={index === 0} />)
				)}
			</article>
		</main>
	)
}
