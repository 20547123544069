// src/components/ImageUpload.tsx
'use client'

import clsx from 'clsx'
import { UploadCloud, XCircle } from 'lucide-react'
import Image from 'next/image'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { type Accept, useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'

interface ImageUploadProps {
	name: string
	accept?: Accept
}

const ImageUpload: React.FC<ImageUploadProps> = ({
	name,
	accept = {
		'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
	},
}) => {
	const {
		control,
		formState: { errors },
		setValue,
		watch,
	} = useFormContext()

	const [preview, setPreview] = useState<string | null>(null)

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles.length > 0) {
				const file = acceptedFiles[0]
				setValue(name, file, { shouldValidate: true })
				const objectUrl = URL.createObjectURL(file)
				setPreview(objectUrl)
			}
		},
		[setValue, name],
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept,
		multiple: false,
	})

	const selectedFile = watch(name) as File | undefined

	// Cleaning up object URLs to free up memory
	useEffect(() => {
		return () => {
			if (preview) {
				URL.revokeObjectURL(preview)
			}
		}
	}, [preview])

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<div className='flex flex-col'>
					<div
						{...getRootProps()}
						className={clsx(
							'flex flex-col items-center justify-center border-2 border-dashed rounded-md p-6 cursor-pointer transition-all duration-300',
							isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-accent hover:bg-card',
						)}
						aria-label='Área de upload de imagem'
					>
						<input {...getInputProps()} />
						{selectedFile && preview ? (
							<div className='relative group'>
								<Image
									src={preview}
									alt='Image preview'
									width={192}
									height={192}
									className='object-cover rounded-md shadow-md transition-transform duration-300 transform group-hover:scale-105'
									unoptimized
								/>
								<button
									type='button'
									onClick={() => {
										setValue(name, undefined)
										setPreview(null)
									}}
									className='absolute top-2 right-2 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition-colors'
									aria-label='Remover imagem'
								>
									<XCircle size={20} />
								</button>
							</div>
						) : (
							<div className='flex flex-col items-center'>
								<UploadCloud className='text-gray-400 mb-2' size={48} />
								<p className='text-gray-600 text-center'>
									{isDragActive
										? 'Solte a imagem aqui...'
										: 'Arraste e solte uma imagem aqui, ou clique para selecionar'}
								</p>
								<p className='text-xs text-gray-500 mt-2'>(Formatos aceitos: JPEG, PNG, GIF)</p>
							</div>
						)}
					</div>
					{errors[name] && <p className='text-red-500 text-sm mt-1'>{errors[name]?.message as string}</p>}
				</div>
			)}
		/>
	)
}

export { ImageUpload }
