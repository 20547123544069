import type { ProgramProtocol, Protocol, ProtocolItem } from '@/types/types'
import api from './api'

export const fetchProtocols = async (programId: number): Promise<ProgramProtocol[]> => {
	const response = await api.get<ProgramProtocol[]>(`/program-protocols/${programId}/protocols`)
	return response.data
}

// Fetch protocol items by protocol ID
export const fetchProtocolItems = async (protocolId: number): Promise<ProtocolItem[]> => {
	const response = await api.get<ProtocolItem[]>(`/protocols/${protocolId}/items`)
	return response.data
}

export const fetchUserProtocols = async (): Promise<Protocol[]> => {
	const response = await api.get<Protocol[]>('/protocols/user')
	return response.data
}

export const fetchProtocol = async (protocolId: number): Promise<Protocol> => {
	const response = await api.get<Protocol>(`/protocols/${protocolId}`)
	return response.data
}

export const createProtocol = async (
	protocolData: Omit<Protocol, 'id' | 'protocolItems' | 'createdAt' | 'updatedAt'>,
): Promise<Protocol> => {
	const response = await api.post<Protocol>('/protocols', protocolData)
	return response.data
}

export const updateProtocol = async (protocolId: number, data: Partial<Protocol>): Promise<Protocol> => {
	const response = await api.put<Protocol>(`/protocols/${protocolId}`, data)
	return response.data
}

export const deleteProtocol = async (protocolId: number): Promise<void> => {
	await api.delete(`/protocols/${protocolId}`)
}

export const addProtocolItem = async (protocolId: number, item: Omit<ProtocolItem, 'id'>): Promise<ProtocolItem> => {
	const response = await api.post<ProtocolItem>(`/protocols/${protocolId}/items`, item)
	return response.data
}

export const updateProtocolItem = async (
	protocolId: number,
	itemId: number,
	item: Partial<ProtocolItem>,
): Promise<ProtocolItem> => {
	const response = await api.put<ProtocolItem>(`/protocols/${protocolId}/items/${itemId}`, item)
	return response.data
}

export const linkProtocolToPost = async (protocolId: number, postId: number): Promise<void> => {
	await api.put(`/posts/${postId}`, { protocolId })
}

export const createOrUpdateProtocol = async (
	protocolData: Partial<Protocol>,
	protocolId?: number,
): Promise<Protocol> => {
	if (protocolId) {
		return updateProtocol(protocolId, protocolData)
	}

	return createProtocol(protocolData as Omit<Protocol, 'id' | 'protocolItems' | 'createdAt' | 'updatedAt'>)
}

export const deleteProtocolItem = async (protocolId: number, itemId: number): Promise<void> => {
	await api.delete(`/protocols/${protocolId}/items/${itemId}`)
}

// protocols.ts

// ... importações existentes ...

export const updateProtocolProgress = async (
	programId: number,
	protocolId: number,
	isChecked: boolean,
): Promise<void> => {
	await api.post(`/program-protocols/${programId}/protocols/${protocolId}/progress`, { isChecked })
}
