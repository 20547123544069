import api from './api'

interface Tag {
	name: string
}

export const fetchTags = async (): Promise<Tag[]> => {
	const response = await api.get<Tag[]>('/tags')
	return response.data
}

export const createTag = async (name: string): Promise<Tag> => {
	const response = await api.post<Tag>('/tags', { name })
	return response.data
}
