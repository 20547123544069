// src/components/CustomHead.tsx
import React from 'react'

const CustomHead = () => (
	<>
		{/* Preconnect para AWS S3 */}
		<link rel='preconnect' href='https://protocols-bucket.s3.us-east-1.amazonaws.com' crossOrigin='anonymous' />
		{/* Preconnect para CloudFront */}
		<link rel='preconnect' href='https://d1n5stvz476vsz.cloudfront.net' crossOrigin='anonymous' />
	</>
)

export { CustomHead }
