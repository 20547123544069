'use client'

import { Button } from '@/components/ui/button'
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui/dialog'
import type { ReactNode } from 'react'

interface ConfirmDialogProps {
	trigger: ReactNode // Accepting any ReactNode as the trigger, including icons
	confirmText: string
	cancelText: string
	title: string
	description: string
	onConfirm: () => void
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	trigger,
	confirmText,
	cancelText,
	title,
	description,
	onConfirm,
}) => {
	return (
		<Dialog>
			<DialogTrigger asChild>{trigger}</DialogTrigger>
			<DialogContent>
				<DialogHeader className='text-center'>
					<DialogTitle className='text-center'>{title}</DialogTitle>
					<DialogDescription className='text-center text-muted'>{description}</DialogDescription>
				</DialogHeader>
				<div className='flex flex-col items-center text-center my-2'>
					<div className='flex gap-2 mt-4'>
						<DialogClose asChild>
							<Button variant='outline' type='button' className='w-44 text-center'>
								{cancelText}
							</Button>
						</DialogClose>
						<Button onClick={onConfirm} className='w-44 text-center'>
							{confirmText}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
