// src/components/ClientLayout.tsx
'use client'

import { Footer, Header, RoleSelectionDialog, Sidebar } from '@/components'
import { updateUserRole } from '@/services/api/users'
import { useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import type React from 'react'
import { useEffect, useState } from 'react'

export const ClientLayout = ({ children }: { children: React.ReactNode }) => {
	const pathname = usePathname()
	const isAuthPage = pathname === '/login' || pathname === '/sign-up'
	const isDashboardRoute = pathname.includes('/dashboard')

	const { data: session, status, update } = useSession()
	const [dialogOpen, setDialogOpen] = useState(false)

	useEffect(() => {
		if (status === 'authenticated' && session.user && !session.user.hasSelectedRole) {
			setDialogOpen(true) // Corrigido para abrir o diálogo
		}
	}, [session, status])

	const handleRoleSelect = async (role: 'CLIENT' | 'COACH') => {
		try {
			await updateUserRole(role)
			await update()
			setDialogOpen(false)
		} catch (error) {
			console.error('Error updating user role:', error)
		}
	}

	return (
		<>
			{dialogOpen && <RoleSelectionDialog onSelectRole={handleRoleSelect} />}
			<div className='flex min-h-screen'>
				{/* Sidebar Fixa para telas grandes (lg e acima) */}
				<div className='hidden lg:flex m-0'>
					{isDashboardRoute && <Sidebar className='hidden lg:flex w-64 shrink-0' />}
				</div>
				<div className={`flex-1 ${isAuthPage ? 'flex items-center justify-center' : ''}`}>
					{!isAuthPage && <Header />}
					<div className='w-full h-full'>{children}</div>
					{!isAuthPage && !isDashboardRoute && <Footer />}
				</div>
			</div>
		</>
	)
}
