// src/app/[locale]/dashboard/tests/tiptap-editor.tsx

'use client'

import MenuBar from '@/components/menu-bar'
import BulletList from '@tiptap/extension-bullet-list'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

interface TipTapEditorProps {
	content: string
	onChange?: (html: string) => void
}

const extensions = [
	Color.configure({ types: [TextStyle.name, ListItem.name] }),
	TextStyle,
	StarterKit.configure({
		bulletList: false, // Disable the default bullet list as we're using our own
		orderedList: {
			keepMarks: true,
			keepAttributes: false,
		},
	}),
	BulletList.configure({
		keepMarks: true,
		keepAttributes: false,
		HTMLAttributes: {
			class: 'bullet-list',
		},
	}),
]

export default function TipTapEditor({ content, onChange }: TipTapEditorProps) {
	return (
		<div className='min-h-[200px] bg-background border border-border dark:border-border rounded-md'>
			<EditorProvider
				slotBefore={<MenuBar />}
				extensions={extensions}
				content={content}
				onUpdate={({ editor }) => {
					onChange?.(editor.getHTML())
				}}
			>
				<div className='prose max-w-none p-4 text-foreground dark:text-foreground'>
					{/* Editor content will be rendered here */}
				</div>
			</EditorProvider>
		</div>
	)
}
