// components/CustomInput.tsx
'use client'

import { FormControl, FormField, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import type React from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'

interface CustomInputProps<T extends FieldValues> {
	control: Control<T>
	name: FieldPath<T>
	label: string
	placeholder: string
	type?: React.HTMLInputTypeAttribute
	className?: string
}

const CustomInput = <T extends FieldValues>({
	control,
	name,
	placeholder,
	type = 'text',
	className,
}: CustomInputProps<T>) => {
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<div className='form-item flex-1 w-full'>
					<div className='flex w-full flex-col'>
						<FormControl>
							<Input
								type={type}
								placeholder={placeholder}
								className={`input-class my-2 w-full ${className}`}
								{...field}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const value = type === 'number' ? Number(e.target.value) : e.target.value
									field.onChange(value)
								}}
							/>
						</FormControl>
						<FormMessage className='form-message mb-3' />
					</div>
				</div>
			)}
		/>
	)
}

export { CustomInput }
