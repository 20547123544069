'use client'

import i18nConfig from '@/app/i18nConfig'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Globe } from 'lucide-react'
import { usePathname, useRouter } from 'next/navigation'
import { useTranslation } from 'react-i18next'

export function LanguageChanger() {
	const { i18n } = useTranslation()
	const currentLocale = i18n.language
	const router = useRouter()
	const currentPathname = usePathname()

	const handleChange = (newLocale: string) => {
		// set cookie for next-i18n-router
		const days = 30
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		const expires = date.toUTCString()
		document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`

		// redirect to the new locale path
		if (currentLocale === i18nConfig.defaultLocale && !i18nConfig.prefixDefault) {
			router.push(`/${newLocale}${currentPathname}`)
		} else {
			router.push(currentPathname.replace(`/${currentLocale}`, `/${newLocale}`))
		}

		router.refresh()
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<span className='flex items-center space-x-2 cursor-pointer' aria-label='Change Language'>
					<Globe size={16} aria-hidden='true' />
					<span className='hidden sm:block'>{currentLocale.toUpperCase()}</span>
				</span>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<DropdownMenuRadioGroup value={currentLocale} onValueChange={handleChange} aria-label='Select Language'>
					<DropdownMenuRadioItem value='pt-BR'>Português</DropdownMenuRadioItem>
					<DropdownMenuRadioItem value='en'>English</DropdownMenuRadioItem>
					<DropdownMenuRadioItem value='es'>Español</DropdownMenuRadioItem>
				</DropdownMenuRadioGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
