// src/components/ui//skeleton-post-card.tsx
import React from 'react'

const SkeletonCard = () => {
	return (
		<div className=' w-full py-2 sm:px-4 animate-pulse'>
			<div className='mx-auto w-full sm:max-w-md md:max-w-lg lg:max-w-xl rounded-md bg-slate-200 dark:bg-slate-700 p-4'>
				{/* Header: Avatar e Informações do Usuário */}
				<div className='flex justify-start mb-4'>
					<div className='w-10 h-10 sm:w-12 sm:h-12 bg-slate-300 dark:bg-slate-600 rounded-full' />
					<div className='ml-2 flex flex-col'>
						<div className='w-24 h-4 bg-slate-300 dark:bg-slate-600 rounded' />
						<div className='w-16 h-3 bg-slate-300 dark:bg-slate-600 rounded mt-1' />
					</div>
				</div>

				{/* Imagem Placeholder */}
				<div className='w-full h-60 sm:h-80 bg-slate-300 dark:bg-slate-600 rounded mb-4' />

				{/* Tags Placeholder */}
				<div className='flex gap-2 pb-2 flex-wrap mb-4'>
					<div className='w-16 h-4 bg-slate-300 dark:bg-slate-600 rounded' />
					<div className='w-16 h-4 bg-slate-300 dark:bg-slate-600 rounded' />
					<div className='w-16 h-4 bg-slate-300 dark:bg-slate-600 rounded' />
				</div>

				{/* Título Placeholder */}
				<div className='w-3/4 h-6 bg-slate-300 dark:bg-slate-600 rounded mb-2' />

				{/* Descrição Placeholder */}
				<div className='w-full h-4 bg-slate-300 dark:bg-slate-600 rounded mb-2' />
				<div className='w-5/6 h-4 bg-slate-300 dark:bg-slate-600 rounded mb-4' />

				{/* Footer Placeholder */}
				<div className='w-20 h-4 bg-slate-300 dark:bg-slate-600 rounded' />
			</div>
		</div>
	)
}

export default SkeletonCard
