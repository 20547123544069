// components/CustomSelect.tsx
'use client'

import { FormControl, FormField, FormLabel, FormMessage } from '@/components/ui/form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import React from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'

interface CustomSelectProps<T extends FieldValues> {
	control: Control<T>
	name: FieldPath<T>
	label: string
	placeholder: string
	options: { value: string; label: string }[]
}

const CustomSelect = <T extends FieldValues>({ control, name, label, placeholder, options }: CustomSelectProps<T>) => {
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<div className='form-item'>
					<FormLabel className='form-label'>{label}</FormLabel>
					<div className='flex w-full flex-col'>
						<FormControl>
							<Select onValueChange={field.onChange} defaultValue={field.value}>
								<SelectTrigger className='w-[180px]'>
									<SelectValue placeholder={placeholder} />
								</SelectTrigger>
								<SelectContent>
									{options.map((option) => (
										<SelectItem key={option.value} value={option.value}>
											{option.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</FormControl>
						<FormMessage className='form-message mb-3' />
					</div>
				</div>
			)}
		/>
	)
}

export { CustomSelect }
