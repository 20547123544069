// src/components/RoleSelectionDialog.tsx
'use client'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/components/ui/dialog'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface RoleSelectionDialogProps {
	onSelectRole: (role: 'CLIENT' | 'COACH') => void
}

const RoleSelectionDialog: React.FC<RoleSelectionDialogProps> = ({ onSelectRole }) => {
	const { t } = useTranslation('common') // Adjust namespace as needed

	return (
		<Dialog open={true}>
			<DialogContent
				className='sm:max-w-md w-10/12 sm:w-full'
				onInteractOutside={(event) => event.preventDefault()}
				onEscapeKeyDown={(event) => event.preventDefault()}
			>
				<div className='flex flex-col items-center mb-4'>
					<DialogTitle>{t('select_role')}</DialogTitle>
					<DialogDescription className='my-2 mx-auto items-center pl-6'>{t('role_disclaimer')}</DialogDescription>
				</div>
				<div className='flex flex-col space-y-4'>
					<Button variant='secondary' onClick={() => onSelectRole('CLIENT')} className='w-full hover:bg-primary'>
						{t('client')}
					</Button>
					<Button variant='secondary' onClick={() => onSelectRole('COACH')} className='w-full hover:bg-primary'>
						{t('coach')}
					</Button>
					<Button variant='secondary' onClick={() => onSelectRole('CLIENT')} className='w-full hover:bg-primary'>
						{t('just_blog')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export { RoleSelectionDialog }
