'use client'

import { Button } from '@/components/ui/button'
import { X } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface MobileMenuProps {
	isOpen: boolean
	onClose: () => void
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
	const { t } = useTranslation('header')

	if (!isOpen) return null

	return (
		<div className='fixed inset-0 bg-background bg-opacity-75 z-50'>
			<div className='flex justify-between items-center p-4'>
				<div className='flex items-center'>
					<Image src='/logo.svg' alt='Protocols Logo' width={120} height={50} />
				</div>
				<Button onClick={onClose} variant='link'>
					<X className='w-6 h-6 text-foreground' />
				</Button>
			</div>
			<div className='flex flex-col items-start pl-8 pt-4 space-y-2'>
				<Link href='/login'>
					<Button
						onClick={onClose}
						className='text-left min-w-full items-start justify-start text-base'
						variant='ghost'
					>
						{t('login')}
					</Button>
				</Link>
				<Link href='/sign-up'>
					<Button onClick={onClose} variant='ghost' className='text-left w-full text-base'>
						{t('create_account')}
					</Button>
				</Link>
			</div>
		</div>
	)
}
