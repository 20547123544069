'use client'

import { LanguageChanger } from '@/components'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
	const { t } = useTranslation('footer')

	return (
		<footer className='container mx-auto px-4 py-8 text-center text-gray-400'>
			<div className='flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4 mb-4'>
				<p>&copy; 2024 Build Up!. {t('all_rights_reserved')}</p>
				<Link href='/privacy-policy' className='hover:underline'>
					{t('privacy_policy')}
				</Link>
			</div>

			<div className='flex items-center justify-center space-x-4 mt-4'>
				<LanguageChanger />
			</div>
		</footer>
	)
}
