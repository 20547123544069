// src/pages/posts/PostFormComponent.tsx

'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/navigation'
import React, { useState, useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type { z } from 'zod'

import { CreatableMultiSelect } from '@/components'
import TipTapEditor from '@/components/tiptap-editor'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { usePostSchema } from '@/lib'
import type { PostData as Post, PostFormProps, Protocol, Tag } from '@/types/types'
import { useTranslation } from 'react-i18next'
import ProtocolForm from './protocol-form'

import { ImageUpload } from '@/components'
import { createPost, updatePost } from '@/services/api/posts'

const PostForm = ({ initialPost, selectedTags = [] }: PostFormProps) => {
	const router = useRouter()
	const [publishOrSave, setPublishOrSave] = useState<boolean>()
	const { toast } = useToast()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [showProtocolForm, setShowProtocolForm] = useState(false)
	const [draftPostId, setDraftPostId] = useState<number | null>(null)
	const [isDraftButtonDisabled, setIsDraftButtonDisabled] = useState(true)
	const [isProtocolButtonVisible, setIsProtocolButtonVisible] = useState(true)
	const { t } = useTranslation('posts')
	const [protocolData, setProtocolData] = useState<Protocol | undefined>(initialPost?.protocol ?? undefined)
	const postSchema = usePostSchema()

	const form = useForm<z.infer<typeof postSchema>>({
		resolver: zodResolver(postSchema),
		defaultValues: initialPost
			? {
					...initialPost,
					tags: initialPost?.post_tags?.map((tag: Tag) => tag.name) || [],
				}
			: {
					title: '',
					description: '',
					published: false,
					post_image: null,
					tags: selectedTags,
				},
		mode: 'onChange',
	})

	useEffect(() => {
		const subscription = form.watch(() => {
			setIsDraftButtonDisabled(false)
		})
		return () => subscription.unsubscribe()
	}, [form])

	const onSubmitProgram: SubmitHandler<z.infer<typeof postSchema>> = async (data) => {
		if (isSubmitting) return
		setIsSubmitting(true)

		try {
			const updatedData = {
				...data,
				published: publishOrSave === true,
			}

			const formData = new FormData()
			formData.append('title', updatedData.title)
			formData.append('description', updatedData.description)
			formData.append('published', updatedData.published.toString())
			formData.append('tags', JSON.stringify(updatedData.tags))

			if (updatedData.post_image instanceof File) {
				formData.append('post_image', updatedData.post_image)
			}

			let res: Post

			if (initialPost || draftPostId) {
				res = await updatePost(initialPost?.id ?? draftPostId!, formData)
			} else {
				res = await createPost(formData)
			}

			const successMessageKey = initialPost ? 'post_updated_success' : 'post_created_success'

			toast({
				description: t(successMessageKey),
				variant: 'success',
			})

			if (!initialPost || publishOrSave === true) {
				router.push('/posts')
			} else {
				form.reset()
			}
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('error_occurred'),
				variant: 'destructive',
			})
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleAddProtocol = async () => {
		const data = form.getValues()
		try {
			const postId = initialPost ? initialPost.id : draftPostId
			if (!postId) {
				const formData = new FormData()

				formData.append('title', data.title)
				formData.append('description', data.description)
				formData.append('published', 'false')

				formData.append('tags', JSON.stringify(data.tags))

				if (data.post_image instanceof File) {
					formData.append('post_image', data.post_image)
				}

				const res = await createPost(formData)

				toast({
					description: t('draft_created'),
					variant: 'success',
				})
				setDraftPostId(res.id)
				setShowProtocolForm(true)
				setIsProtocolButtonVisible(false)
			} else {
				setShowProtocolForm(true)
				setIsProtocolButtonVisible(false)
			}
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('error_occurred'),
				variant: 'destructive',
			})
		}
	}

	const handleProtocolSaved = (protocol: Protocol) => {
		setProtocolData(protocol)
		setShowProtocolForm(false)
	}

	return (
		<div className='mx-auto px-3 py-4 max-w-sm sm:max-w-3xl'>
			<h2 className='text-2xl font-bold mb-4'>{initialPost ? t('edit_post') : t('create_new_post')}</h2>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmitProgram)} className='space-y-4'>
					<FormField
						control={form.control}
						name='title'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input placeholder={t('title')} {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name='description'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<TipTapEditor content={field.value ?? ''} onChange={field.onChange} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name='tags'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<CreatableMultiSelect
										control={form.control}
										name={field.name}
										placeholder={t('tags')}
										selectedTags={form.watch('tags')}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name='post_image'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<ImageUpload name='post_image' />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<div className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 mt-4 sm:space-x-2 w-full justify-end items-end'>
						<div className='flex flex-row space-x-2'>
							{isProtocolButtonVisible && (
								<Button
									type='button'
									onClick={handleAddProtocol}
									disabled={!form.formState.isValid || isSubmitting}
									variant='secondary'
								>
									{t('add_protocol')}
								</Button>
							)}
							{(!initialPost || (initialPost && !initialPost.published)) && (
								<Button
									type='button'
									onClick={() => setPublishOrSave(false)}
									disabled={isDraftButtonDisabled || isSubmitting}
									variant='secondary'
								>
									{t('save_draft')}
								</Button>
							)}
						</div>

						<Button
							type='submit'
							onClick={() => setPublishOrSave(true)}
							disabled={!form.formState.isValid || isSubmitting}
							className={`bg-primary text-primary-foreground ${
								isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-foreground hover:text-primary'
							}`}
						>
							{initialPost?.published ? t('update') : t('publish')}
						</Button>
					</div>
				</form>
			</Form>

			{(showProtocolForm || initialPost?.protocol) && (
				<ProtocolForm
					postId={initialPost?.id ?? draftPostId!}
					initialProtocol={protocolData}
					onProtocolSaved={handleProtocolSaved}
				/>
			)}
		</div>
	)
}

export { PostForm }
