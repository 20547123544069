'use client'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
// Components UI
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { PasswordInput } from '@/components/ui/password-input'
import { useToast } from '@/components/ui/use-toast'
import { useLoginSchema } from '@/lib'
import type { LoginCredentials } from '@/types/types'
import { zodResolver } from '@hookform/resolvers/zod'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { z } from 'zod'

import { CustomInput } from '@/components'
import { Google } from '@/icons'
import { signIn } from 'next-auth/react'

export const LoginForm = () => {
	const loginSchema = useLoginSchema()
	const { t } = useTranslation('login')
	const router = useRouter()
	const searchParams = useSearchParams()
	const [error, setError] = useState<string | null>(null)
	const { toast } = useToast()

	const form = useForm<z.infer<typeof loginSchema>>({
		resolver: zodResolver(loginSchema),
		defaultValues: {
			usernameOrEmail: '',
			password: '',
		},
	})

	useEffect(() => {
		if (searchParams.has('verified') && searchParams.get('verified') === 'true') {
			toast({
				description: t('account_verified'),
				variant: 'success',
			})
		}
	}, [searchParams, toast, t])

	const handleSubmit = async (data: LoginCredentials) => {
		setError(null) // Reset error state

		try {
			const res = await signIn('credentials', {
				redirect: false,
				usernameOrEmail: data.usernameOrEmail,
				password: data.password,
			})

			if (res?.error) {
				setError(res.error)
				toast({
					description: t('error_login_submission'),
					variant: 'destructive',
				})
			} else {
				router.push('/') // Redirect after successful login
			}
		} catch (error) {
			setError(t('unexpected_error_occurred'))
		}
	}

	const callbackUrl = `${process.env.NEXT_PUBLIC_URL}`

	return (
		<div className='min-h-screen w-full flex flex-col items-center justify-center p-4 bg-gradient-background-light dark:bg-gradient-card'>
			<Image src='/logo.svg' alt='Protocols Logo' width={250} height={250} />
			<Card className='w-full max-w-md border-none shadow-md bg-card/40'>
				<CardHeader>
					<CardTitle className='text-2xl text-center text-primary'>{t('welcome')}</CardTitle>
					<CardDescription className='text-center text-muted-foreground'>{t('enter_credentials')}</CardDescription>
				</CardHeader>

				<CardContent>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-6'>
							<div className='space-y-2'>
								<CustomInput
									control={form.control}
									name='usernameOrEmail'
									label={t('username_or_email')}
									placeholder={t('username_or_email')}
								/>
							</div>
							<div className='space-y-2'>
								<FormField
									control={form.control}
									name='password'
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<PasswordInput id='password' placeholder={t('password')} {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<Button type='submit' className='w-full bg-primary text-primary-foreground hover:bg-primary/90'>
								{t('login')}
							</Button>

							<div className='flex items-center my-4'>
								<div className='flex-grow border-t border-gray-300' />
								<span className='mx-2 text-gray-500'>{t('or')}</span>
								<div className='flex-grow border-t border-gray-300' />
							</div>

							<Button
								type='button'
								onClick={() => signIn('google', { redirect: true, callbackUrl })}
								className='w-full bg-white border-1 shadow-sm text-muted-foreground hover:shadow-md
                 hover:bg-gray-50 flex items-center justify-center'
							>
								<Google className='w-5 h-5 fill-current mx-2' />
								<span>{t('sign_in_with_google')}</span>
							</Button>
						</form>
					</Form>
				</CardContent>
				<CardFooter className='flex flex-col space-y-4'>
					<Link className='text-sm text-muted-foreground hover:text-primary text-center' href='/forgot-password'>
						{t('forgot_password')}
					</Link>
					<div className='text-sm text-muted-foreground text-center'>
						{t('create_new_account')}{' '}
						<Link className='text-primary hover:text-primary/90' href='/sign-up'>
							{t('create_account')}
						</Link>
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}
