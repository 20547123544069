//src/services/api/programs.ts

import type { Program, ProgramEnrollment, ProgramProtocol, ProgramUpdateInput } from '@/types/types'
import api from './api'

// Fetch all programs
export const fetchPrograms = async (): Promise<Program[]> => {
	const response = await api.get<Program[]>('/programs')
	return response.data
}

// Fetch a single program by ID
export const fetchProgram = async (programId: number): Promise<Program> => {
	const response = await api.get<Program>(`/programs/${programId}`)
	return response.data
}

// Delete a program
export const deleteProgram = async (programId: number): Promise<void> => {
	await api.delete(`/programs/${programId}`)
}

// Create a new program
export const createProgram = async (
	program: Omit<Program, 'id' | 'createdAt' | 'updatedAt' | 'enrollments'>,
): Promise<Program> => {
	const response = await api.post<Program>('/programs', program)
	return response.data
}

// Update an existing program
export const updateProgram = async (program: ProgramUpdateInput): Promise<Program> => {
	const response = await api.put<Program>(`/programs/${program.id}`, program)
	return response.data
}

// Link a protocol to a program
export const linkProtocolToProgram = async (programId: number, protocolId: number): Promise<ProgramProtocol> => {
	const response = await api.post<ProgramProtocol>('/program-protocols', {
		programId,
		protocolId,
	})
	return response.data
}

// Delete a program protocol
export const deleteProgramProtocol = async (programProtocolId: number): Promise<void> => {
	await api.delete(`/program-protocols/items/${programProtocolId}`)
}

// Enroll a client in a program
export const enrollClientInProgram = async (programId: number, clientId: number): Promise<void> => {
	await api.post(`/programs/${programId}/enroll`, { clientId })
}

// Fetch all enrollments
export const fetchEnrollments = async (): Promise<ProgramEnrollment[]> => {
	const response = await api.get<ProgramEnrollment[]>('/programs/enrollments')
	return response.data
}

// Fetch active enrollments
export const fetchActiveEnrollments = async (): Promise<ProgramEnrollment[]> => {
	const response = await api.get<ProgramEnrollment[]>('/programs/active-enrollments')
	return response.data
}

// Enroll multiple clients in a program
export const enrollClientsInProgram = async (programId: number, clientIds: number[]): Promise<void> => {
	await api.post(`/programs/${programId}/enroll`, { clientIds })
}

// Remove multiple clients from a program
export const removeClientsFromProgram = async (programId: number, clientIds: number[]): Promise<void> => {
	await api.delete(`/programs/${programId}/remove-clients`, {
		data: { clientIds }, // Enviar os clientIds no corpo da requisição
	})
}
