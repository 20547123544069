// src/components/ClientHome/ClientHome.tsx

'use client'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { GradientSpinner } from '@/components/ui/spinner'
import { fetchActiveEnrollments } from '@/services/api/programs'
import type { ProgramEnrollment } from '@/types/types'
import { Apple, Book, Dumbbell, NotebookTabs, Pill, ScanSearch } from 'lucide-react'
import Link from 'next/link'
import type React from 'react'
import { useEffect, useState } from 'react'

const ClientHome: React.FC = () => {
	const [programId, setProgramId] = useState<number | null>(null)
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const loadActiveEnrollments = async () => {
			try {
				const enrollments: ProgramEnrollment[] = await fetchActiveEnrollments()
				if (enrollments.length > 0) {
					// TO DO -  fazer com que a API retorne só o id do program ativo
					setProgramId(enrollments[0].program.id)
				}
			} catch (err) {
				console.error('Erro ao buscar inscrições ativas:', err)
				setError('Falha ao carregar inscrições ativas.')
			} finally {
				setLoading(false)
			}
		}

		loadActiveEnrollments()
	}, [])

	if (loading) {
		return (
			<div className='flex justify-center items-center h-screen'>
				<GradientSpinner />
			</div>
		)
	}

	if (error) {
		return (
			<div className='flex justify-center items-center h-screen'>
				<p className='text-red-500'>{error}</p>
			</div>
		)
	}

	return (
		<main className='container mx-auto px-4 py-8'>
			<h1 className='text-3xl font-bold mb-6 text-center'>Bem-vindo à Sua Área Cliente</h1>

			<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
				{/* Card: Treino */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=WORKOUT`} className='block'>
						<Card className='hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center space-x-4'>
								<Dumbbell className='text-primary dark:text-primary-foreground' size={32} />
								<CardTitle>Treino</CardTitle>
							</CardHeader>
							<CardContent>
								<CardDescription>
									Acesse e acompanhe suas rotinas de exercícios personalizadas para alcançar seus objetivos.
								</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='bg-muted dark:bg-muted-foreground p-6 rounded-lg shadow-md'>
						<CardHeader className='flex items-center space-x-4'>
							<Dumbbell className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>Treino</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>Você não tem nenhum programa de treino ativo no momento.</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Card: Dietas Personalizadas */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=DIET`} className='block'>
						<Card className='hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center space-x-4'>
								<Apple className='text-primary dark:text-primary-foreground' size={32} />
								<CardTitle>Dieta</CardTitle>
							</CardHeader>
							<CardContent>
								<CardDescription>
									Veja suas dietas planejadas para atingir seus objetivos nutricionais e de saúde.
								</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='bg-muted dark:bg-muted-foreground p-6 rounded-lg shadow-md'>
						<CardHeader className='flex items-center space-x-4'>
							<Apple className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>Dieta</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>Você não tem nenhum programa de dieta ativo no momento.</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Card: Protocolos de Suplementação */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=OTHER`} className='block'>
						<Card className='hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center space-x-4'>
								<Pill className='text-primary dark:text-primary-foreground' size={32} />
								<CardTitle>Protocolos</CardTitle>
							</CardHeader>
							<CardContent>
								<CardDescription>
									Gerencie seus suplementos com nossos protocolos recomendados para otimizar seus resultados.
								</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='bg-muted dark:bg-muted-foreground p-6 rounded-lg shadow-md'>
						<CardHeader className='flex items-center space-x-4'>
							<Pill className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>Protocolos</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>Você não tem nenhum protocolo ativo no momento.</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Outros Cartões (Meus Programas e Progresso) */}
				<Link href='/my-programs' className='block'>
					<Card className='hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center space-x-4'>
							<NotebookTabs className='text-primary dark:text-primary-foreground' size={32} />
							<CardTitle>Meus Programas</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>
								Tenha em um lugar só a listagem de programas, treinos, dietas, protocolos.
							</CardDescription>
						</CardContent>
					</Card>
				</Link>

				<Link href='/client/progress' className='block'>
					<Card className='hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center space-x-4'>
							<ScanSearch className='text-primary dark:text-primary-foreground' size={32} />
							<CardTitle>Explorar</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>
								Monitore seu progresso com gráficos detalhados e estatísticas para manter-se motivado.
							</CardDescription>
						</CardContent>
					</Card>
				</Link>

				<Link href='/blog' className='block'>
					<Card className='hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center space-x-4'>
							<Book className='text-primary dark:text-primary-foreground' size={32} />
							<CardTitle>Blog</CardTitle>
						</CardHeader>
						<CardContent>
							<CardDescription>
								Acesse nossos artigos e dicas exclusivas para aprimorar seu conhecimento e alcançar seus objetivos.
							</CardDescription>
						</CardContent>
					</Card>
				</Link>
			</div>
		</main>
	)
}

export { ClientHome }
