// components/CreatableMultiSelect.tsx
'use client'
import { useToast } from '@/components/ui/use-toast'
import { createTag, fetchTags } from '@/services/api/tags'
import type { CreatableMultiSelectProps } from '@/types/types'
import { useSession } from 'next-auth/react'
import { useTheme } from 'next-themes'
import React, { useState, useEffect, forwardRef } from 'react'
import { useController } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'

// Definindo o CreatableSelectWithRef com ref genérico
const CreatableSelectWithRef = forwardRef<any, any>((props, ref) => <CreatableSelect {...props} ref={ref} />)

CreatableSelectWithRef.displayName = 'CreatableSelectWithRef'

export function CreatableMultiSelect({
	control,
	name,
	placeholder = 'Tags',
	selectedTags = [],
}: CreatableMultiSelectProps) {
	const { field } = useController({ control, name })
	const [isLoading, setIsLoading] = useState(true)
	const [options, setOptions] = useState<{ label: string; value: string }[]>([])
	const { toast } = useToast()
	const { data: session, status } = useSession()
	const isAuthenticated = status === 'authenticated'
	const { theme = 'system' } = useTheme()

	useEffect(() => {
		const loadTags = async () => {
			try {
				const tags = await fetchTags()
				setOptions(
					tags.map((tag) => ({
						label: tag.name,
						value: tag.name,
					})),
				)
				setIsLoading(false)
			} catch (error) {
				console.error('Error fetching tags:', error)
				setIsLoading(false)
			}
		}

		loadTags()
	}, [])

	const handleCreate = async (inputValue: string) => {
		if (!isAuthenticated) {
			toast({
				description: 'You must be logged in to create a tag.',
				variant: 'destructive',
			})
			return
		}

		try {
			const newTag = await createTag(inputValue)
			const newOption = { label: newTag.name, value: newTag.name }
			setOptions((prev) => [...prev, newOption])
			field.onChange([...(field.value || []), newOption.value])
		} catch (error) {
			console.error('Error creating tag:', error)
			toast({ description: 'Failed to create tag', variant: 'destructive' })
		}
	}

	const getStyles = (theme: string) => ({
		control: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 23%, 38%)' : 'hsl(219, 47%, 90%)',
			borderColor: theme === 'dark' ? 'hsl(210, 17%, 96%)' : 'hsl(0, 0%, 84.7%)',
			color: theme === 'dark' ? 'hsl(0, 22.033898305084744%, 88.4313725490196%)' : 'hsl(223, 23%, 28%)',
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 16%, 20%)' : 'hsl(242.3, 43%, 95%)',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isFocused
				? theme === 'dark'
					? 'hsl(219.7, 77.1%, 80.6%)'
					: 'hsl(219.7, 77.1%, 80.6%)'
				: theme === 'dark'
					? 'hsl(223, 16%, 20%)'
					: 'hsl(242.3, 43%, 95%)',
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		multiValue: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(219.7, 77.1%, 80.6%)' : 'hsl(219.7, 77.1%, 80.6%)',
		}),
		multiValueLabel: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: 'text-muted-foreground',
		}),
		input: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
	})

	return (
		<CreatableSelectWithRef
			isMulti
			placeholder={placeholder}
			options={options}
			value={selectedTags.map((tag) => ({ value: tag, label: tag }))}
			onChange={(selectedOptions: any[]) => {
				field.onChange(selectedOptions.map((option: any) => option.value))
			}}
			onCreateOption={handleCreate}
			name={name}
			isLoading={isLoading}
			styles={getStyles(theme)}
			ref={field.ref} // Passando o ref corretamente
		/>
	)
}
