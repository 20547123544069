import { cn } from '@/lib/utils'

type SpinnerSize = 'small' | 'default' | 'large'

interface SpinnerProps {
	size?: SpinnerSize
	className?: string
}

function GradientSpinner({ size = 'default', className }: SpinnerProps) {
	const sizeClasses: Record<SpinnerSize, string> = {
		small: 'w-16 h-16',
		default: 'w-24 h-24',
		large: 'w-32 h-32',
	}

	const strokeWidth: Record<SpinnerSize, number> = {
		small: 8,
		default: 10,
		large: 12,
	}

	return (
		<div className={cn('fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50', className)}>
			<div className={cn('relative', sizeClasses[size])}>
				<svg className='animate-spin' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
					<title>Spin</title>
					<defs>
						<linearGradient id='spinner-gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
							<stop offset='0%' style={{ stopColor: '#FC660C', stopOpacity: 1 }} />
							<stop offset='50%' style={{ stopColor: '#38405B', stopOpacity: 1 }} />
							<stop offset='100%' style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
						</linearGradient>
					</defs>
					<circle
						cx='50'
						cy='50'
						r='45'
						stroke='url(#spinner-gradient)'
						strokeWidth={strokeWidth[size]}
						fill='none'
						strokeLinecap='round'
						strokeDasharray='283'
						strokeDashoffset='100'
					/>
				</svg>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	)
}

export { GradientSpinner }
