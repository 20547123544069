'use client'
import type { MultiSelectProps } from '@/types/types'
import { useTheme } from 'next-themes'
import React from 'react'
import { useController } from 'react-hook-form'
import Select from 'react-select'

export function MultiSelect({ control, name, options, placeholder }: MultiSelectProps) {
	const { field } = useController({ control, name })
	const { theme = 'system' } = useTheme()

	const getStyles = (theme: string) => ({
		control: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 23%, 38%)' : 'hsl(219, 47%, 90%)',
			borderColor: theme === 'dark' ? 'hsl(210, 17%, 96%)' : 'hsl(0, 0%, 84.7%)',
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(223, 16%, 20%)' : 'hsl(242.3, 43%, 95%)',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isFocused
				? theme === 'dark'
					? 'hsl(219.7, 77.1%, 80.6%)'
					: 'hsl(219.7, 77.1%, 80.6%)'
				: theme === 'dark'
					? 'hsl(223, 16%, 20%)'
					: 'hsl(242.3, 43%, 95%)',
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		multiValue: (provided: any) => ({
			...provided,
			backgroundColor: theme === 'dark' ? 'hsl(219.7, 77.1%, 80.6%)' : 'hsl(219.7, 77.1%, 80.6%)',
		}),
		multiValueLabel: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		placeholder: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
		input: (provided: any) => ({
			...provided,
			color: theme === 'dark' ? 'hsl(240, 83%, 97%)' : 'hsl(223, 23%, 28%)',
		}),
	})

	return (
		<Select
			isMulti
			value={options.filter((option) => (field.value || []).includes(option.value))}
			onChange={(selectedOptions) => {
				const values = selectedOptions.map((option) => option.value)
				field.onChange(values)
			}}
			options={options}
			placeholder={placeholder}
			styles={getStyles(theme)}
		/>
	)
}
