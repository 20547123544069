'use client'
import { CustomSelect } from '@/components'
import ProtocolItemsTable from '@/components/protocol-items-table'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import { useProtocolItemSchema, useProtocolSchema } from '@/lib'
import {
	addProtocolItem,
	createOrUpdateProtocol,
	fetchProtocolItems,
	linkProtocolToPost,
	updateProtocolItem,
} from '@/services/api/protocols'
import type { ProtocolFormProps, ProtocolItem } from '@/types/types'
import { zodResolver } from '@hookform/resolvers/zod'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { z } from 'zod'

import { CustomInput } from '@/components'

const ProtocolForm: React.FC<ProtocolFormProps> = ({
	postId,
	programId,
	initialProtocol,
	onSubmit,
	onProtocolSaved,
}) => {
	const { toast } = useToast()
	const { t } = useTranslation('protocols')
	const protocolSchema = useProtocolSchema()
	const itemSchema = useProtocolItemSchema()
	const [items, setItems] = useState<ProtocolItem[]>(initialProtocol?.protocolItems || [])
	const [protocolId, setProtocolId] = useState<number | null>(initialProtocol?.id ?? null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isProtocolSaved, setIsProtocolSaved] = useState(!!initialProtocol)
	const [isAddItemDisabled, setIsAddItemDisabled] = useState(true)
	const [editItem, setEditItem] = useState<ProtocolItem | null>(null)
	const [isEditDisabled, setIsEditDisabled] = useState(false)

	const protocolForm = useForm<z.infer<typeof protocolSchema>>({
		resolver: zodResolver(protocolSchema),
		defaultValues: initialProtocol || {
			title: '',
			subtitle: '',
			type: 'OTHER',
		},
	})

	const itemForm = useForm<z.infer<typeof itemSchema>>({
		resolver: zodResolver(itemSchema),
		defaultValues: {
			item: '',
			quantity: '',
			impact: '',
		},
	})

	useEffect(() => {
		const subscription = protocolForm.watch(() => {
			setIsEditDisabled(false)
		})
		return () => subscription.unsubscribe()
	}, [protocolForm])

	const handleProtocolSubmit: SubmitHandler<z.infer<typeof protocolSchema>> = async (data) => {
		setIsSubmitting(true)
		try {
			const protocolData = {
				title: data.title,
				subtitle: data.subtitle,
				type: data.type,
				postId,
				programId,
			}

			const createdProtocol = await createOrUpdateProtocol(protocolData, protocolId || undefined)

			toast({ description: t('protocol_saved_success'), variant: 'success' })
			setIsProtocolSaved(true)
			setIsEditDisabled(true)
			setProtocolId(createdProtocol.id)
			if (onSubmit) onSubmit(createdProtocol)
			if (onProtocolSaved) onProtocolSaved(createdProtocol)

			if (postId && !protocolId) {
				await linkProtocolToPost(createdProtocol.id, postId)
			}
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('an_error_occurred'),
				variant: 'destructive',
			})
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleAddItem = async () => {
		const data = itemForm.getValues()
		if (!protocolId) {
			toast({
				description: t('protocol_create_before_add'),
				variant: 'destructive',
			})
			return
		}

		const itemData: Omit<ProtocolItem, 'id'> = {
			protocolId: protocolId!,
			item: data.item,
			quantity: data.quantity,
			impact: data.impact,
		}

		try {
			const newItem = await addProtocolItem(protocolId!, itemData)
			toast({ description: t('item_added_success'), variant: 'success' })
			setItems([...items, newItem])
			itemForm.reset()
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('an_error_occurred'),
				variant: 'destructive',
			})
		}
	}

	const handleEditItem = async () => {
		if (!editItem) return
		const data = itemForm.getValues()

		try {
			const updatedItem = await updateProtocolItem(editItem.protocolId, editItem.id, {
				item: data.item,
				quantity: data.quantity,
				impact: data.impact,
			})

			toast({ description: t('item_updated_success'), variant: 'success' })
			setItems(items.map((item) => (item.id === editItem.id ? updatedItem : item)))
			setEditItem(null)
			itemForm.reset()
		} catch (error: any) {
			toast({
				description: error.response?.data?.message || t('an_error_occurred'),
				variant: 'destructive',
			})
		}
	}

	useEffect(() => {
		if (protocolId) {
			const loadProtocolItems = async () => {
				try {
					const items = await fetchProtocolItems(protocolId)
					setItems(items)
				} catch (error) {
					console.error('Error fetching protocol items:', error)
					toast({
						description: t('error_fetching_protocol_items'),
						variant: 'destructive',
					})
				}
			}
			loadProtocolItems()
		}
	}, [protocolId, toast, t])

	useEffect(() => {
		const subscription = itemForm.watch((value) => {
			const { item, quantity } = value
			setIsAddItemDisabled(!item || !quantity)
		})
		return () => subscription.unsubscribe()
	}, [itemForm])

	useEffect(() => {
		if (editItem) {
			itemForm.setValue('item', editItem.item)
			itemForm.setValue('quantity', editItem.quantity)
			itemForm.setValue('impact', editItem.impact || '')
		}
	}, [editItem, itemForm])

	return (
		<div className='container mx-auto max-w-3xl p-4'>
			<Form {...protocolForm}>
				<form onSubmit={protocolForm.handleSubmit(handleProtocolSubmit)} className='space-y-4'>
					<CustomInput
						control={protocolForm.control}
						name='title'
						label={t('title')}
						placeholder={t('title_placeholder')}
						type='text'
					/>

					<CustomInput
						control={protocolForm.control}
						name='subtitle'
						label={t('subtitle')}
						placeholder={t('subtitle_placeholder')}
						type='text'
					/>
					<CustomSelect
						control={protocolForm.control}
						name='type'
						label={t('type')}
						placeholder={t('select_type')}
						options={[
							{ value: 'WORKOUT', label: t('workout') },
							{ value: 'DIET', label: t('diet') },
							{ value: 'OTHER', label: t('other') },
						]}
					/>
					<div className='w-full flex justify-end'>
						<Button type='submit' disabled={isSubmitting || isEditDisabled}>
							{t('save_protocol')}
						</Button>
					</div>
				</form>
			</Form>

			{protocolId && (
				<div className='mt-4'>
					<h3 className='text-xl font-bold mb-2'>{t('add_items')}</h3>
					<Form {...itemForm}>
						<form onSubmit={itemForm.handleSubmit(editItem ? handleEditItem : handleAddItem)}>
							<CustomInput
								control={itemForm.control}
								name='item'
								label={t('item')}
								placeholder={t('item_placeholder')}
								type='text'
							/>

							<CustomInput
								control={itemForm.control}
								name='quantity'
								label={t('quantity')}
								placeholder={t('quantity_placeholder')}
								type='text'
							/>

							<CustomInput
								control={itemForm.control}
								name='impact'
								label={t('impact')}
								placeholder={t('impact_placeholder')}
								type='text'
							/>
							<Button type='submit' disabled={isAddItemDisabled}>
								{editItem ? t('update_item') : t('add_items')}
							</Button>
						</form>
					</Form>
				</div>
			)}

			{items.length > 0 && <ProtocolItemsTable protocolItems={items} setItems={setItems} setEditItem={setEditItem} />}
		</div>
	)
}

export default ProtocolForm
