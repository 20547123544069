import type { PostData as Post } from '@/types/types'
import api from './api'

// Fetch published posts
// Agora a função apenas retorna os posts recebidos, sem necessidade de filtrar
export const fetchPublishedPosts = async (): Promise<Post[]> => {
	const response = await api.get<Post[]>('/posts')
	return response.data.map((post) => ({
		...post,
		post_tags: post.post_tags || [],
	}))
}

// Fetch user-specific posts
export const fetchUserPosts = async (): Promise<Post[]> => {
	const response = await api.get<Post[]>('/posts/user')
	return response.data
}

// Fetch a single post by ID
export const fetchPost = async (postId: string): Promise<Post> => {
	const response = await api.get<Post>(`/posts/${postId}`)
	return response.data
}

// Delete a post
export const deletePost = async (postId: number): Promise<void> => {
	await api.delete(`/posts/${postId}`)
}

// Publish a post
export const publishPost = async (postId: number): Promise<void> => {
	await api.put(`/posts/${postId}`, { published: true })
}

// Create a new post
export const createPost = async (data: FormData): Promise<Post> => {
	const response = await api.post<Post>('/posts', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
	return response.data
}

// Update an existing post
export const updatePost = async (postId: number, data: FormData): Promise<Post> => {
	const response = await api.put<Post>(`/posts/${postId}`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
	return response.data
}
