// src/components/menu-bar.tsx

'use client'

import { useCurrentEditor } from '@tiptap/react'
import {
	Bold,
	Code,
	Eraser,
	Heading1,
	Heading2,
	Italic,
	List,
	ListOrdered,
	Minus,
	Palette,
	Quote,
	Redo,
	Strikethrough,
	Trash2,
	Undo,
} from 'lucide-react'

export default function MenuBar() {
	const { editor } = useCurrentEditor()

	if (!editor) {
		return null
	}

	return (
		<div className='sticky top-6 z-20  border-b border-border p-2'>
			<div className='flex flex-wrap gap-1 bg-background'>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleBold().run()}
					disabled={!editor.can().chain().focus().toggleBold().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('bold') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Bold'
				>
					<Bold className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleItalic().run()}
					disabled={!editor.can().chain().focus().toggleItalic().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('italic') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Italic'
				>
					<Italic className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleStrike().run()}
					disabled={!editor.can().chain().focus().toggleStrike().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('strike') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Strike'
				>
					<Strikethrough className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleCode().run()}
					disabled={!editor.can().chain().focus().toggleCode().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('code') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Code'
				>
					<Code className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<div className='w-px h-8 bg-border dark:bg-border mx-1 self-center' />
				<button
					type='button'
					onClick={() => editor.chain().focus().unsetAllMarks().run()}
					className='p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors'
					title='Clear marks'
				>
					<Eraser className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().clearNodes().run()}
					className='p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors'
					title='Clear nodes'
				>
					<Trash2 className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<div className='w-px h-8 bg-border dark:bg-border mx-1 self-center' />
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('heading', { level: 1 }) ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Heading 1'
				>
					<Heading1 className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('heading', { level: 2 }) ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Heading 2'
				>
					<Heading2 className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('bulletList') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Bullet list'
				>
					<List className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('orderedList') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Ordered list'
				>
					<ListOrdered className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().toggleBlockquote().run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('blockquote') ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Blockquote'
				>
					<Quote className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().setHorizontalRule().run()}
					className='p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors'
					title='Horizontal rule'
				>
					<Minus className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<div className='w-px h-8 bg-border dark:bg-border mx-1 self-center' />
				<button
					type='button'
					onClick={() => editor.chain().focus().undo().run()}
					disabled={!editor.can().chain().focus().undo().run()}
					className='p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors'
					title='Undo'
				>
					<Undo className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<button
					type='button'
					onClick={() => editor.chain().focus().redo().run()}
					disabled={!editor.can().chain().focus().redo().run()}
					className='p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors'
					title='Redo'
				>
					<Redo className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
				<div className='w-px h-8 bg-border mx-1 self-center' />
				<button
					type='button'
					onClick={() => editor.chain().focus().setColor('#958DF1').run()}
					className={`p-2 rounded hover:bg-muted dark:hover:bg-muted-foreground transition-colors ${
						editor.isActive('textStyle', { color: '#958DF1' }) ? 'bg-muted dark:bg-muted-foreground' : ''
					}`}
					title='Purple text'
				>
					<Palette className='w-5 h-5 text-foreground dark:text-foreground' />
				</button>
			</div>
		</div>
	)
}
