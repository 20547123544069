// Header.tsx
'use client'

import { MobileMenu, Sidebar, UserMenu } from '@/components'
import { Button } from '@/components/ui/button'
import { APP_NAME } from '@/utils/app-config'
import { Menu } from 'lucide-react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useState } from 'react'

export function Header() {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const { data: session, status } = useSession()
	const isAuthenticated = status === 'authenticated'

	const pathname = usePathname()
	const isDashboard = pathname.startsWith('/dashboard')

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen)
	}

	return (
		<header className='flex w-full z-40 sticky top-0 bg-background shadow-md'>
			<div className='flex justify-between container mx-auto items-center py-2'>
				{/* Para dispositivos móveis e md */}
				<div className='lg:hidden flex items-center w-full'>
					<div className='flex items-center'>
						{isAuthenticated && <Sidebar className='lg:hidden' />}
						<Link href='/' className='ml-2'>
							<Image alt='logo' src='/logo.svg' height={24} width={84} priority style={{ width: 74, height: 'auto' }} />
						</Link>
					</div>
					<div className='flex-grow' />
					{isAuthenticated ? (
						<UserMenu />
					) : (
						<Button variant='ghost' onClick={toggleMobileMenu}>
							<Menu className='w-6 h-6' />
						</Button>
					)}
				</div>

				{/* Para telas grandes (lg e acima) */}
				<div className={`hidden lg:flex items-center w-full ${isDashboard ? 'justify-end' : 'justify-between'} `}>
					{!isDashboard && (
						<Link href='/' className='flex items-center space-x-2'>
							<Image alt='logo' src='/logo.svg' height={22} width={64} priority style={{ width: 64, height: 'auto' }} />
							<span className='text-xl font-extrabold'>{APP_NAME}</span>
						</Link>
					)}

					<UserMenu />
				</div>
			</div>
			{!isAuthenticated && <MobileMenu isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />}
		</header>
	)
}
